// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-our-brands-page-js": () => import("/Users/angel/Sites/www-theventagroup-com/theeventagroup/src/pages/ourBrandsPage.js" /* webpackChunkName: "component---src-pages-our-brands-page-js" */),
  "component---src-pages-contact-us-page-js": () => import("/Users/angel/Sites/www-theventagroup-com/theeventagroup/src/pages/contactUsPage.js" /* webpackChunkName: "component---src-pages-contact-us-page-js" */),
  "component---src-pages-about-us-page-js": () => import("/Users/angel/Sites/www-theventagroup-com/theeventagroup/src/pages/aboutUsPage.js" /* webpackChunkName: "component---src-pages-about-us-page-js" */),
  "component---src-pages-press-page-js": () => import("/Users/angel/Sites/www-theventagroup-com/theeventagroup/src/pages/pressPage.js" /* webpackChunkName: "component---src-pages-press-page-js" */),
  "component---src-pages-suppliers-page-js": () => import("/Users/angel/Sites/www-theventagroup-com/theeventagroup/src/pages/suppliersPage.js" /* webpackChunkName: "component---src-pages-suppliers-page-js" */),
  "component---src-pages-jobs-page-js": () => import("/Users/angel/Sites/www-theventagroup-com/theeventagroup/src/pages/jobsPage.js" /* webpackChunkName: "component---src-pages-jobs-page-js" */),
  "component---src-pages-home-page-js": () => import("/Users/angel/Sites/www-theventagroup-com/theeventagroup/src/pages/homePage.js" /* webpackChunkName: "component---src-pages-home-page-js" */),
  "component---src-pages-404-js": () => import("/Users/angel/Sites/www-theventagroup-com/theeventagroup/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("/Users/angel/Sites/www-theventagroup-com/theeventagroup/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("/Users/angel/Sites/www-theventagroup-com/theeventagroup/src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */)
}

